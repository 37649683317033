import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useLocation } from "react-router-dom";
import CONFIG from "config/environment";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import './productos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge, faTh, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';


import { Grid2ColsProductos, Grid3ColsProductos, Grid4ColsProductos } from 'components/grid-productos';

import { Colors } from 'styles';
import { IconGrid4Active, IconGrid4 } from 'images';

import { useSnackbar } from 'notistack';

const BootstrapInput = withStyles((theme) => ({
    input: {
        fontSize: 16,
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useSelectStyles = makeStyles((theme) => ({
    formControl: {
        margin: '0px 2px',
        minWidth: 200
    },
    selectEmpty: {
        marginTop: '0px'
    },
}));
const useGridButtonStyles = makeStyles((theme) => ({
    margen: {
        margin: '0px 0px',
    },
    extendedIcon: {
        marginRight: '0px',
    },
}));
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Productos = props => {
    const classes = useStyles();
    let query = useQuery();
    const { enqueueSnackbar } = useSnackbar();
    const selectClass = useSelectStyles();
    const gridButtonClass = useGridButtonStyles();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    // const [listaProductos, setListaProductos] = useState([]);
    const [filtro, setFiltro] = useState({});
    const [gridType, setGridType] = useState('4cols');
    const [productos, setProductos] = useState([]);
    // const [producto, setProducto] = useState(null);
    const [categorias1, setCategorias1] = useState([]);
    const [categorias2, setCategorias2] = useState([]);
    const [categorias3, setCategorias3] = useState([]);
    const [categorias4, setCategorias4] = useState([]);
    const [categorias5, setCategorias5] = useState([]);
    const [configuraciones, setConfiguraciones] = useState(null);
    const [filtroJerarquia, setFiltroJerarquia] = useState({
        nivel1: 'todos',
        nivel2: 'todos',
        nivel3: 'todos',
        nivel4: 'todos',
        nivel5: 'todos',
    });
    //SEARCH
    // let searchInput = React.createRef();
    const [requestEnProceso, setRequestEnProceso] = useState(true);
    // const [searchText, setSearchText] = useState('');
    // const [typing, setTyping] = useState(false);
    // const [typingTimeout, setTypingTimeout] = useState(0);
    const [paginador, setPaginador] = useState({
        cuantos: 12,
        selectCuantos: [{ label: '12', value: 12 }, { label: '24', value: 24 }, { label: '36', value: 36 }, { label: '60', value: 60 }, { label: '120', value: 120 }],
        numResultados: 0,
        paginaActual: 0,
        cantidadPaginas: 1,
        maxSize: 10
    });



    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        let rand = Helper.getRandomNum(1, 1000);
        dispatch(AppActions.setRand(rand));
        document.title = CONFIG.APP_NAME + ' - Productos';
        // console.log(query.get("categoria"));
        if (query.get("categoria")) {
            let fts = { ...filtros, filtros: { ...filtros.filtros, jerarquiaId: query.get("categoria"), rand: rand } };
            setFiltroJerarquia({
                nivel1: query.get("categoria"),
                nivel2: 'todos',
                nivel3: 'todos',
                nivel4: 'todos',
                nivel5: 'todos',
            });
            getConfig(fts);
        } else {
            let fts = { ...filtros, filtros: { ...filtros.filtros, rand: rand } };
            getConfig(fts);
        }
        // setListaProductos(lista);        
    }, []);
    useEffect(() => {
        console.log('filtro', filtro);
    }, [filtro]);

    const onChangeFiltro = (event, key) => {
        if (key === 'nivel1') {
            let cats2 = [];
            let cats3 = [];
            let cats4 = [];
            let cats5 = [];
            setFiltroJerarquia({
                nivel1: event.target.value,
                nivel2: 'todos',
                nivel3: 'todos',
                nivel4: 'todos',
                nivel5: 'todos',
            });
            for (let i in categorias1) {
                if (categorias1[i].id === event.target.value && categorias1[i].subJerarquias.length > 0) {
                    cats2 = categorias1[i].subJerarquias;
                }
            }
            for (let i in cats2) {
                if (cats2[i].id === event.target.value && cats2[i].subJerarquias.length > 0) {
                    cats3 = cats2[i].subJerarquias;
                }
            }
            for (let i in cats3) {
                if (cats3[i].id === event.target.value && cats3[i].subJerarquias.length > 0) {
                    cats4 = cats3[i].subJerarquias;
                }
            }
            for (let i in cats4) {
                if (cats4[i].id === event.target.value && cats4[i].subJerarquias.length > 0) {
                    cats5 = cats4[i].subJerarquias;
                }
            }
            setCategorias2(cats2);
            setCategorias3(cats3);
            setCategorias4(cats4);
            setCategorias5(cats5);
        }
        if (key === 'nivel2') {
            let cats3 = [];
            let cats4 = [];
            let cats5 = [];
            setFiltroJerarquia({
                nivel1: filtroJerarquia.nivel1,
                nivel2: event.target.value,
                nivel3: 'todos',
                nivel4: 'todos',
                nivel5: 'todos',
            });
            for (let i in categorias2) {
                if (categorias2[i].id === event.target.value && categorias2[i].subJerarquias.length > 0) {
                    cats3 = categorias2[i].subJerarquias;
                }
            }
            for (let i in cats3) {
                if (cats3[i].id === event.target.value && cats3[i].subJerarquias.length > 0) {
                    cats4 = cats3[i].subJerarquias;
                }
            }
            for (let i in cats4) {
                if (cats4[i].id === event.target.value && cats4[i].subJerarquias.length > 0) {
                    cats5 = cats4[i].subJerarquias;
                }
            }
            setCategorias3(cats3);
            setCategorias4(cats4);
            setCategorias5(cats5);
        }
        if (key === 'nivel3') {
            let cats4 = [];
            let cats5 = [];
            setFiltroJerarquia({
                nivel1: filtroJerarquia.nivel1,
                nivel2: filtroJerarquia.nivel2,
                nivel3: event.target.value,
                nivel4: 'todos',
                nivel5: 'todos',
            });
            for (let i in categorias3) {
                if (categorias3[i].id === event.target.value && categorias3[i].subJerarquias.length > 0) {
                    cats4 = categorias3[i].subJerarquias;
                }
            }
            for (let i in cats4) {
                if (cats4[i].id === event.target.value && cats4[i].subJerarquias.length > 0) {
                    cats5 = cats4[i].subJerarquias;
                }
            }
            setCategorias4(cats4);
            setCategorias5(cats5);
        }
        if (key === 'nivel4') {
            let cats5 = [];
            setFiltroJerarquia({
                nivel1: filtroJerarquia.nivel1,
                nivel2: filtroJerarquia.nivel2,
                nivel3: filtroJerarquia.nivel3,
                nivel4: event.target.value,
                nivel5: 'todos',
            });
            for (let i in categorias4) {
                if (categorias4[i].id === event.target.value && categorias4[i].subJerarquias.length > 0) {
                    cats5 = categorias4[i].subJerarquias;
                }
            }
            setCategorias5(cats5);
        }
        if (key === 'nivel5') {
            setFiltroJerarquia({
                nivel1: filtroJerarquia.nivel1,
                nivel2: filtroJerarquia.nivel2,
                nivel3: filtroJerarquia.nivel3,
                nivel4: filtroJerarquia.nivel4,
                nivel5: event.target.value
            });
        }
        let fts = { ...filtros };
        if (event.target.value === 'todos') {
            let rand = Helper.getRandomNum(1, 1000);
            dispatch(AppActions.setRand(rand));
            fts.filtros.rand = rand;
            if (key === 'nivel1') {
                fts.filtros.jerarquiaId = '';
            }
            if (key === 'nivel2') {
                fts.filtros.jerarquiaId = filtroJerarquia.nivel1;
                const { history } = props;
                if (history) history.push('/productos?categoria=' + filtroJerarquia.nivel1);
            }
            if (key === 'nivel3') {
                fts.filtros.jerarquiaId = filtroJerarquia.nivel2;
                const { history } = props;
                if (history) history.push('/productos?categoria=' + filtroJerarquia.nivel2);
            }
            if (key === 'nivel4') {
                fts.filtros.jerarquiaId = filtroJerarquia.nivel3;
                const { history } = props;
                if (history) history.push('/productos?categoria=' + filtroJerarquia.nivel3);
            }
            if (key === 'nivel5') {
                fts.filtros.jerarquiaId = filtroJerarquia.nivel4;
                const { history } = props;
                if (history) history.push('/productos?categoria=' + filtroJerarquia.nivel4);
            }
        } else {
            fts.filtros.jerarquiaId = event.target.value;
            const { history } = props;
            if (history) history.push('/productos?categoria=' + event.target.value);
        }
        fts.desde = 0;
        buscarResultados(fts);
    }
    const [filtros, setFiltros] = useState({
        cuantos: paginador.cuantos,
        desde: 0,
        filtros: {
            nombre: [''],
            jerarquiaId: ''
        }
    });
    useEffect(() => {
        // searchInput.current.focus();
    }, [productos]);
    // const cambiarPagina = (data) => {
    //     console.log('cambiarPagina', data);
    //     const paginaActual = data.selected;
    //     if (!requestEnProceso) {
    //         const fts = { ...filtros, desde: paginador.cuantos * (paginaActual) };
    //         setFiltros(fts);
    //         setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginaActual }));
    //         buscarResultados(fts);
    //     }
    // }
    const cambioCuantos = (event) => {
        const cts = event.target.value;
        const fts = { ...filtros, desde: 0, cuantos: cts };
        setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
        buscarResultados(fts);
    }
    // const cambioTextoBusqueda = (event) => {
    //     setSearchText(event.target.value);
    //     if (typingTimeout) {
    //         clearTimeout(typingTimeout);
    //     }
    //     let texto = (!event.target.value) ? "" : event.target.value;
    //     let fts = { ...filtros };
    //     fts.filtros.nombre[0] = texto;
    //     setTyping(false);
    //     setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
    // }
    const armarCategorias = (jquias) => {
        let cats = [{ id: 'todos', nombre: 'Todos', subJerarquias: [] }];
        //nivel 1
        for (let i in jquias) {
            jquias[i].subJerarquias = [];
            if (!jquias[i].jerarquiaPapaId) {
                cats.push(jquias[i]);
            }
        }
        //nivel 2
        for (let i in cats) {
            for (let x in jquias) {
                if (jquias[x].jerarquiaPapaId === cats[i].id) {
                    if (cats[i].subJerarquias.length === 0) {
                        cats[i].subJerarquias.push({ id: 'todos', nombre: 'Todos', subJerarquias: [] });
                    }
                    cats[i].subJerarquias.push(jquias[x]);
                }
            }
        }
        //nivel 3
        for (let i in cats) {
            for (let x in cats[i].subJerarquias) {
                for (let y in jquias) {
                    if (jquias[y].jerarquiaPapaId === cats[i].subJerarquias[x].id) {
                        if (cats[i].subJerarquias[x].subJerarquias.length === 0) {
                            cats[i].subJerarquias[x].subJerarquias.push({ id: 'todos', nombre: 'Todos', subJerarquias: [] });
                        }
                        cats[i].subJerarquias[x].subJerarquias.push(jquias[y]);
                    }
                }
            }
        }
        //nivel 4
        for (let i in cats) {
            for (let x in cats[i].subJerarquias) {
                for (let z in cats[i].subJerarquias[x].subJerarquias) {
                    for (let y in jquias) {
                        if (jquias[y].jerarquiaPapaId === cats[i].subJerarquias[x].subJerarquias[z].id) {
                            if (cats[i].subJerarquias[x].subJerarquias[z].subJerarquias.length === 0) {
                                cats[i].subJerarquias[x].subJerarquias[z].subJerarquias.push({ id: 'todos', nombre: 'Todos', subJerarquias: [] });
                            }
                            cats[i].subJerarquias[x].subJerarquias[z].subJerarquias.push(jquias[y]);
                        }
                    }
                }
            }
        }
        //nivel 5
        for (let i in cats) {
            for (let x in cats[i].subJerarquias) {
                for (let z in cats[i].subJerarquias[x].subJerarquias) {
                    for (let n in cats[i].subJerarquias[x].subJerarquias[z].subJerarquias) {
                        for (let y in jquias) {
                            if (jquias[y].jerarquiaPapaId === cats[i].subJerarquias[x].subJerarquias[z].subJerarquias[n].id) {
                                if (cats[i].subJerarquias[x].subJerarquias[z].subJerarquias[n].subJerarquias.length === 0) {
                                    cats[i].subJerarquias[x].subJerarquias[z].subJerarquias[n].subJerarquias.push({ id: 'todos', nombre: 'Todos', subJerarquias: [] });
                                }
                                cats[i].subJerarquias[x].subJerarquias[z].subJerarquias[n].subJerarquias.push(jquias[y]);
                            }
                        }
                    }
                }
            }
        }
        console.log('cats', cats);
        setCategorias1(cats);
    }
    const getConfig = (fts) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/configuraciones'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getConfiguraciones response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setConfiguraciones(response.data.datos);
                if (response.data && response.data.datos && response.data.datos.jerarquias) {
                    armarCategorias([...response.data.datos.jerarquias]);
                }
                buscarResultados(fts);
            }
        }, error => {
            Helper.log('error getConfiguraciones', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
        });
    }
    const buscarResultados = (fts) => {
        setFiltros(fts);
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('p/ver-productos'),
            method: 'POST',
            data: fts
        };
        Api.requestAxios(options, response => {
            Helper.log('searchProductos response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setPaginador((pgndr) => {
                    return {
                        ...pgndr,
                        numResultados: response.data.datos.numResultados,
                        cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
                    }
                });
                setProductos(response.data.datos.items);
            }
            setRequestEnProceso(false);
        }, error => {
            Helper.log('error searchProductos', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            setRequestEnProceso(false);
            enqueueSnackbar('No se pudo traer los productos', { variant: 'error' });
        });
    }
    // const atras = () => {
    //     if (filtros.desde > 0) {
    //         if (!requestEnProceso) {
    //             const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
    //             setFiltros(fts);
    //             setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
    //             buscarResultados(fts);
    //         }
    //     }
    // }
    // const siguiente = () => {
    //     if (filtros.desde + paginador.cuantos < paginador.numResultados) {
    //         if (!requestEnProceso) {
    //             const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
    //             setFiltros(fts);
    //             setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
    //             buscarResultados(fts);
    //         }
    //     }
    // }
    const atras = () => {
        if (filtros.desde > 0) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
                buscarResultados(fts);
            }
        }
    }
    const siguiente = () => {
        if (filtros.desde + paginador.cuantos < paginador.numResultados) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
                buscarResultados(fts);
            }
        }
    }
    return (
        <div id="pagina-producto">
            <div className="w3-block">
                <div className="w3-content">
                    <div className="w3-block w3-padding">
                        <h2 className="w3-text-gray">Tienda</h2>
                    </div>
                    <div className="w3-row">
                        <div className="w3-col " style={{ width: '230px', paddingLeft: '8px' }}>
                            {!requestEnProceso && productos.length > 0 ? <p>Mostrando {productos.length} resultados de {paginador.numResultados}</p> : null}
                        </div>
                        <div className="w3-rest w3-mobile">
                            <div className="w3-cell w3-right">
                                <div className="w3-cell" style={{ height: '40px', verticalAlign: 'middle' }}>
                                    {(categorias1.length > 0) ? <FormControl variant="outlined" className={selectClass.formControl}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label1"
                                            id="demo-simple-select-outlined1"
                                            value={filtroJerarquia['nivel1']}
                                            onChange={(e) => onChangeFiltro(e, 'nivel1')}
                                        >
                                            {categorias1.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
                                        </Select>
                                    </FormControl> : null}
                                    {(categorias2.length > 0) ? <FormControl variant="outlined" className={selectClass.formControl}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label2"
                                            id="demo-simple-select-outlined2"
                                            value={filtroJerarquia['nivel2']}
                                            onChange={(e) => onChangeFiltro(e, 'nivel2')}
                                        >
                                            {categorias2.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
                                        </Select>
                                    </FormControl> : null}
                                    {(categorias3.length > 0) ? <FormControl variant="outlined" className={selectClass.formControl}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label3"
                                            id="demo-simple-select-outlined3"
                                            value={filtroJerarquia['nivel3']}
                                            onChange={(e) => onChangeFiltro(e, 'nivel3')}
                                        >
                                            {categorias3.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
                                        </Select>
                                    </FormControl> : null}
                                    {(categorias4.length > 0) ? <FormControl variant="outlined" className={selectClass.formControl}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label4"
                                            id="demo-simple-select-outlined4"
                                            value={filtroJerarquia['nivel4']}
                                            onChange={(e) => onChangeFiltro(e, 'nivel4')}
                                        >
                                            {categorias4.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
                                        </Select>
                                    </FormControl> : null}
                                    {(categorias5.length > 0) ? <FormControl variant="outlined" className={selectClass.formControl}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label5"
                                            id="demo-simple-select-outlined5"
                                            value={filtroJerarquia['nivel5']}
                                            onChange={(e) => onChangeFiltro(e, 'nivel5')}
                                        >
                                            {categorias5.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
                                        </Select>
                                    </FormControl> : null}
                                    {/* {(typeof filtro['key2'] !== 'undefined') ? <FormControl variant="outlined" className={selectClass.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Por calificación</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={filtro['key2']}
                                        onChange={(e) => onChangeFiltro(e, 'key2')}
                                        label="Por calificación">
                                        <MenuItem value={'Todos'}>Todos</MenuItem>
                                        <MenuItem value={'5 estrellas'}>5 estrellas</MenuItem>
                                        <MenuItem value={'4 estrellas'}>4 estrellas</MenuItem>
                                        <MenuItem value={'3 estrellas'}>3 estrellas</MenuItem>
                                        <MenuItem value={'2 estrellas'}>2 estrellas</MenuItem>
                                        <MenuItem value={'1 estrellas'}>1 estrellas</MenuItem>
                                    </Select>
                                </FormControl> : null} */}
                                </div>
                                <div className="w3-hide-small w3-cell" style={{ height: '40px', verticalAlign: 'middle' }}>
                                    <IconButton aria-label="2 columnas" className={gridButtonClass.margen} onClick={() => setGridType('2cols')}>
                                        <FontAwesomeIcon icon={faThLarge} color={(gridType === '2cols' ? Colors.THEME : '#DDDDDD')} />
                                    </IconButton>
                                    <IconButton aria-label="2 columnas" className={gridButtonClass.margen} onClick={() => setGridType('3cols')}>
                                        <FontAwesomeIcon icon={faTh} color={(gridType === '3cols' ? Colors.THEME : '#DDDDDD')} />
                                    </IconButton>
                                    <IconButton aria-label="2 columnas" className={gridButtonClass.margen} onClick={() => setGridType('4cols')}>
                                        <img src={(gridType === '4cols' ? IconGrid4Active : IconGrid4)} alt="" style={{ width: '24px', margin: '0px' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!requestEnProceso && productos.length === 0 ? <h2 className="w3-text-theme w3-center" style={{ marginTop: '60px' }}>Próximamente disponible</h2> : null}
                    {(gridType === '2cols') ? <Grid2ColsProductos items={productos} /> : null}
                    {(gridType === '3cols') ? <Grid3ColsProductos items={productos} /> : null}
                    {(gridType === '4cols') ? <Grid4ColsProductos items={productos} /> : null}

                    {/* {(gridType === '2cols') ? <Grid2ColsProductos items={listaProductos} /> : null}
                    {(gridType === '3cols') ? <Grid3ColsProductos items={listaProductos} /> : null}
                    {(gridType === '4cols') ? <Grid4ColsProductos items={listaProductos} /> : null} */}


                    {(productos && productos.length > 0) ? <div className="w3-block" style={{ height: '30px', marginBottom: 40 }}>
                        <div style={{ width: '100%', maxWidth: '380px', float: 'right' }}>
                            <div className="w3-cell w3-cell-middle"><span>Productos por página </span></div>
                            <div className="w3-cell">
                                {paginador.selectCuantos.length > 0 ? <FormControl variant="outlined">
                                    <Select
                                        size="small"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={paginador.cuantos}
                                        onChange={(e) => cambioCuantos(e)}
                                        input={<BootstrapInput />}>
                                        {paginador.selectCuantos.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                                    </Select>
                                </FormControl> : null}
                            </div>
                            <div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
                            <div className="w3-cell w3-cell-middle">
                                <IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
                                    <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                                </IconButton>
                                <IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
                                    <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                </IconButton>
                            </div>
                        </div>
                    </div> : null}

                </div>
            </div>
        </div>
    );
}
export default withRouter(Productos);