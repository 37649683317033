import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import { makeStyles } from '@material-ui/core/styles';
import './mi-perfil.css';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import { withRouter, useLocation } from "react-router-dom";
import CONFIG from "config/environment";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import GoogleMapReact from 'google-map-react';
import ProductosList from 'components/producto/ProductosList';

const useStyles = makeStyles({
	canceladoAlert: {
	  "& .MuiAlert-icon": {
		marginTop: 6
	  }
	}
  });

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Pedido = props => {
	const classes = useStyles();
	let query = useQuery();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [pedido, setPedido] = useState(null);
	const [moneda, setMoneda] = useState('₲');
	const [subTotal, setSubTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [entrega, setEntrega] = useState(null);
	const [precioDelivery, setPrecioDelivery] = useState(20000);
	const [lugares, setLugares] = useState(null);
	const [mapas, setMapas] = useState(null);
	const [mapa, setMapa] = useState(null);
	const center = {
		lat: -25.276750711185308,
		lng: -57.63522231951356
	};
	const zoom = 15;

	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Mi perfil | Pedido';
		if (query.get("pedidoId")) {
			getPedido(query.get("pedidoId"));
		}
	}, []);

	useEffect(() => {
		if (pedido) {
			let sTotal = 0;
			for (var i in pedido.items) {
				sTotal = sTotal + (pedido.items[i].monto * pedido.items[i].cantidad);
			}
			let tl = sTotal;
			if (entrega === 'delivery' && state && state.appReducer && state.appReducer.precioDelivery) {
				tl = tl + state.appReducer.precioDelivery;
			}
			setSubTotal(sTotal);
			setTotal(tl);
		}
	}, [pedido, entrega]);

	//mapa
	const Marker = () => <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} size="3x" style={{
		position: "absolute",
		transform: "translate(-50%, -100%)"
	}} />;

	const getPedido = (id) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('u/mis-pedidos/' + id),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getPedido response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setPedido(response.data.datos);
				if (response.data.datos.puntoEntrega) {
					if (typeof response.data.datos.puntoEntrega.lat !== 'undefined' && response.data.datos.puntoEntrega.lat !== null && typeof response.data.datos.puntoEntrega.lon !== 'undefined' && response.data.datos.puntoEntrega.lon !== null) {
						// setCenter({
						//     lat: response.data.datos.puntoEntrega.lat,
						//     lng: response.data.datos.puntoEntrega.lon
						// });  
						const coords = {
							lat: parseFloat(response.data.datos.puntoEntrega.lat),
							lng: parseFloat(response.data.datos.puntoEntrega.lon)
						}
						if (mapas) {
							const bounds = new mapas.LatLngBounds();
							bounds.extend(new mapas.LatLng(coords.lat, coords.lng - 0.005562184202671051,));
							bounds.extend(new mapas.LatLng(coords.lat, coords.lng + 0.005562184202671051,));
							bounds.extend(new mapas.LatLng(coords.lat, coords.lng,));
							mapa.fitBounds(bounds);
						}
					}
				}
			}
		}, error => {
			Helper.log('error getPedido', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al traer pedido', { variant: 'error' });
		});
	}
	const handleApiLoaded = (map, maps, places) => {
		setMapas(maps);
		setMapa(map);
		setLugares(places);
		if (pedido && pedido.puntoEntrega) {
			if (typeof pedido.puntoEntrega.lat !== 'undefined' && pedido.puntoEntrega.lat !== null && typeof pedido.puntoEntrega.lon !== 'undefined' && pedido.puntoEntrega.lon !== null) {
				const coords = {
					lat: parseFloat(pedido.puntoEntrega.lat),
					lng: parseFloat(pedido.puntoEntrega.lon)
				}
				const bounds = new maps.LatLngBounds();
				bounds.extend(new maps.LatLng(coords.lat, coords.lng - 0.005562184202671051,));
				bounds.extend(new maps.LatLng(coords.lat, coords.lng + 0.005562184202671051,));
				bounds.extend(new maps.LatLng(coords.lat, coords.lng,));
				map.fitBounds(bounds);
			}
		}

	}

	return (
		<div id="pagina-pedido" className="w3-block">
			<b>Pedido</b>
			{pedido?.cancelado && (
				<Alert severity="error" sx={{ mt: 2 }} className={classes.canceladoAlert}>
					<h2 style={{ marginTop: 0, marginBottom: 5 }}>Pedido cancelado</h2>
					<i>{pedido.motivoCancelado}</i>
				</Alert>
			)}
			{(pedido && pedido.usuario) ? (
				<div className="w3-col l12 w3-border w3-padding-small bs-bbox">
					<b>Datos facturación</b>
					{pedido.datosFacturacion ? <div className="w3-row">
						{pedido.datosFacturacion.razonSocial ? <div className="w3-col l6 w3-padding-small bs-bbox">
							<TextField fullWidth margin="normal" size="small" variant="outlined"
								id="input-razonSocial"
								label="Razón social"
								type="text"
								name="razonSocial"
								value={pedido.datosFacturacion.razonSocial} />
						</div> : null}
						{pedido.datosFacturacion.ruc ? <div className="w3-col l6 w3-padding-small bs-bbox">
							<TextField fullWidth margin="normal" size="small" variant="outlined"
								id="input-ruc"
								label="RUC"
								type="text"
								name="ruc"
								value={pedido.datosFacturacion.ruc} />
						</div> : null}
					</div> : null}
				</div>
			) : null}
			{(pedido && pedido.items) ? (
				<div className="w3-block w3-border w3-padding-small bs-bbox">
					<b>Productos</b>
					<ProductosList
						productos={pedido.items}
						actionsDisabled={state.appReducer.actionsDisabled}
						precioKey={'monto'}
						noActions
					/>
				</div>
			) : null}

			{pedido && pedido.items && pedido.items.length > 0 ? (
				<table className="w3-table w3-border w3-bordered">
					<tbody className="w3-border">
						<tr>
							<td className="w3-padding">
								<h3>Subtotal</h3>
							</td>
							<td className="w3-padding">
								<h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(subTotal)}</h3>
							</td>
						</tr>
						{pedido.costoEnvio ? <tr>
							<td className="w3-padding">
								<h3>Envío</h3>
							</td>
							<td className="w3-padding">
								<h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(pedido.costoEnvio)}</h3>
							</td>
						</tr> : null}
						{pedido.puntoEntrega ? <tr>
							<td colSpan="2" className="w3-padding">
								<div className="w3-row">
									<div className="w3-col l6" style={{ height: '260px' }}>
										<GoogleMapReact
											bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
											defaultCenter={center}
											defaultZoom={zoom}
											yesIWantToUseGoogleMapApiInternals
											onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
										>
											{(pedido.puntoEntrega) ? <Marker lat={pedido.puntoEntrega.lat} lng={pedido.puntoEntrega.lon} /> : null}
										</GoogleMapReact>
									</div>
									<div className="w3-col l6 w3-padding-small bs-bbox">
										{pedido.puntoEntrega.direccion ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
											name="direccion"
											type="text"
											label="Dirección"
											value={pedido.puntoEntrega.direccion} /> : null}<br />
										{(pedido.puntoEntrega.barrio && pedido.puntoEntrega.barrio.nombre) ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
											name="barrio"
											type="text"
											label="Barrio"
											value={pedido.puntoEntrega.barrio.nombre} /> : null}<br />
										{(pedido.puntoEntrega.ciudad && pedido.puntoEntrega.ciudad.nombre) ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
											name="ciudad"
											type="text"
											label="Ciudad"
											value={pedido.puntoEntrega.ciudad.nombre} /> : null}<br />
										{pedido.puntoEntrega.observaciones ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
											name="observaciones"
											type="text"
											label="Observaciones"
											value={pedido.puntoEntrega.observaciones} /> : null}<br />
									</div>
								</div>
							</td>
						</tr> : null}
						<tr>
							<td className="w3-padding">
								<h2 style={{ color: Colors.THEME }}>Total</h2>
							</td>
							<td className="w3-padding">
								{(pedido && pedido.montoTotal) ? <h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(pedido.montoTotal)}</h2> : null}
							</td>
						</tr>
					</tbody>
				</table>
			) : null}
		</div>
	);
}
export default withRouter(Pedido);