import React from 'react'
import ProductoRow from './ProductoRow'

// Componente para mostrar una tabla de productos
export default function ProductosTable(props) {
	const { productos, precioKey = 'precio', totalLabel, quitarDelCarrito, menos, mas, onChangeCantidad, noActions } = props
	return (
		<table className="w3-table w3-border w3-bordered mi-tabla">
			<thead className="w3-border">
				<tr>
					{!noActions && (
						<th></th>
					)}
					<th></th>
					<th>Producto</th>
					<th>Precio</th>
					<th>Cantidad</th>
					<th>{totalLabel}</th>
				</tr>
			</thead>
			<tbody className="w3-border">
				{Object.keys(productos).map((id) => (
					<ProductoRow
						key={id}
						producto={productos[id]}
						precioKey={precioKey}
						totalLabel={totalLabel}
						quitarDelCarrito={quitarDelCarrito}
						menos={menos}
						mas={mas}
						onChangeCantidad={onChangeCantidad}
						noActions={noActions}
					/>
				))}
			</tbody>
		</table>
	)
}