export const ENTREGA_TIPO_RETIRO_TIENDA = 'tienda' // confirmar pedido sin pagar
export const ENTREGA_TIPO_ENVIO = 'envio' // confirmar pedido sin pagar
export const ENTREGA_TIPO_ONLINE_RETIRO_TIENDA = 'online-tienda' // confirmar pedido al pagar
export const ENTREGA_TIPO_ONLINE_ENVIO = 'online-envio' // confirmar pedido al pagar

// Modificar esto de acuerdo a las necesidades del proyecto
export const OPCIONES_TIPOS_PEDIDO = [
	{
		label: 'Envío',
		value: ENTREGA_TIPO_ONLINE_ENVIO
	},
	{
		label: 'Retiro en tienda',
		value: ENTREGA_TIPO_ONLINE_RETIRO_TIENDA
	},
]

export const isConBancard = (tipo) => {
	let conBancard = false
	if (tipo === 'online-tienda' || tipo === 'online-envio') {
		conBancard = true
	}

	return conBancard
}

export const isConSeleccionPuntoEntrega = (tipo) => {
	let conSeleccion = false
	if (tipo === 'envio' || tipo === 'online-envio') {
		conSeleccion = true
	}

	return conSeleccion
}

// Los elementos del array se utilizan tanto como value como para label
export const OPCIONES_CANTIDAD_PEDIDOS = [
	10, 20, 30, 50, 100
]