const production = true;
const ID = 'floreria-franco';
const APP_NAME = 'Florería Franco, Sucursal San Lorenzo';
const GOOGLE_MAP_KEY = 'AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s';
const GOOGLE_RECAPTCHA_SITEKEY = '6LcC12MpAAAAAFZoMYuKsWbhuvEbTGilggd4WTPB';
const DEVELOPMENT = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'http://192.168.100.10/floreriafranco/web',//localhost
    BASENAME: 'floreriafranco/web/',//subdirectorio del host
    URL_BASE: 'http://192.168.100.10/floreriafranco/server/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: true
}
const PRODUCTION = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'https://admin.floreriafranco.com.py',//production (copiar y pegar en package.json --> homepage)
    BASENAME: '/',//subdirectorio
    URL_BASE: 'https://api.floreriafranco.com.py/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: false
}
export default (production) ? PRODUCTION : DEVELOPMENT;