import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import { Helper } from 'utils'
import { Api } from 'services'

const IconButtonSmall = withStyles({
	root: {
		height: 32,
		width: 32,
		fontSize: '16px'
	}
})(IconButton)

const IconButtonExtraSmall = withStyles({
	root: {
		height: 28,
		width: 28,
		margin: '0px',
		fontSize: '14px'
	}
})(IconButton)

export default function ProductoRow(props) {
	const { producto, precioKey, totalLabel, quitarDelCarrito, menos, mas, onChangeCantidad, actionsDisabled, noActions } = props
	
	return (
		<tr>
			{!noActions && (
				<td>
					<IconButtonSmall aria-label="Eliminar" onClick={() => quitarDelCarrito(producto.id)}>
						<FontAwesomeIcon icon={faTrash} color="#DDDDDD" />
					</IconButtonSmall>
				</td>
			)}
			<td>
				<div
					className="thumb-container"
					style={{
						backgroundImage: "url('" + Api.route(producto.multimedia[0].url) + "')",
					}}
				></div>
			</td>
			<td>{producto.nombre}</td>
			<td>{'₲ ' + Helper.numberUi(producto[precioKey])}</td>
			<td style={{ width: '120px', padding: '8px 0px' }}>
				{noActions ? (
					<span style={{ marginLeft: 8 }}>{producto.cantidad}</span>
				) : (
					<FormControl variant="outlined">
						<InputLabel></InputLabel>
						<OutlinedInput
							id={"input-cantidad-" + producto.id}
							type="text"
							fullWidth={true}
							value={producto.cantidad}
							onChange={(e) => onChangeCantidad(e, producto)}
							disabled={actionsDisabled ? true : false}
							startAdornment={
								<InputAdornment position="start">
									<IconButtonExtraSmall
										aria-label="Menos"
										onClick={() => menos(producto)}
										onMouseDown={(e) => e.preventDefault()}
										edge="start"
									>
										<FontAwesomeIcon icon={faMinus} size="xs" />
									</IconButtonExtraSmall>
								</InputAdornment>
							}
							endAdornment={
								<InputAdornment position="end">
									<IconButtonExtraSmall
										aria-label="Más"
										onClick={() => mas(producto)}
										onMouseDown={(e) => e.preventDefault()}
										edge="end"
									>
										<FontAwesomeIcon icon={faPlus} size="xs" />
									</IconButtonExtraSmall>
								</InputAdornment>
							}
							inputProps={{ style: { textAlign: 'center' } }}
						/>
					</FormControl>
				)}
			</td>
			<td>{'₲ ' + Helper.numberUi(producto.cantidad * producto[precioKey])}</td>
		</tr>
	);
}