// BASIC
export const PRIMARY = '#E81315';
export const SECONDARY = '#DA2324';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// ACTIONS
export const SUCCESS = '#28A745';
export const INFO = '##cff4fc';
export const WARNING = '#ffae00';
export const ALERT = '#cc4b37';
export const DEFAULT = '#E0E0E0';

// GRAYSCALE
export const GRAY_LIGHT = '#e6e6e6';
export const GRAY_MEDIUM = '#cacaca';
export const GRAY_DARK = '#8a8a8a';

// BRANDS
export const BRAND_FACEBOOK = '#3b5998';
export const BRAND_TWITTER = '#1da1f2';
export const BRAND_INSTAGRAM = '#c13584';

// THEME
// Utilizar los mismos valores que están en styles/w3-theme.css
export const THEME_L5 = '#fef1f1';
export const THEME_L4 = '#fbcfd0';
export const THEME_L3 = '#f79fa0';
export const THEME_L2 = '#f36f71';
export const THEME_L1 = '#ef3f41';
export const THEME = '#e81315';
export const THEME_D1 = '#d01114';
export const THEME_D2 = '#b90f12';
export const THEME_D3 = '#a20d10';
export const THEME_D4 = '#8b0b0d';
export const THEME_D5 = '#74090b';