import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import './footer.css';
import { Logo } from 'assets/logos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagramSquare, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
// import { faFacebook, faWhatsapp, faTwitterSquare, faInstagram, faInstagramSquare, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Colors } from 'styles';
import { facebookURL, FAQ_URL, instagramURL, TyC_URL } from 'utils/constants';
const Footer = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    // const items = props.items;
    const [contenido, setContenido] = useState(null);
    // const [idioma, setIdioma] = useState('es');
    const idioma = 'es';
    const [whatsapp, setWhatsapp] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefono, setTelefono] = useState(null);
    const [direccion, setDireccion] = useState(null);
	const [facebook, setFacebook] = useState(null);
	const [instagram, setInstagram] = useState(null);
    useEffect(() => {
        getContenidoSeccion();
    }, []);
    useEffect(() => {
        if (contenido) {
            if (contenido.contacto && contenido.contacto.length > 0) {
                if (contenido.contacto[0] && contenido.contacto[0].partes && contenido.contacto[0].partes.length > 0) {
                    for (var i in contenido.contacto[0].partes) {
                        if (contenido.contacto[0].partes[i].grupos) {
                            let grupos = contenido.contacto[0].partes[i].grupos;
                            for (var keyGrupo in grupos) {
                                let grupo = grupos[keyGrupo];
                                for (var keyValor in grupo) {
                                    let valor = grupo[keyValor];
                                    if (keyValor === 'Email' && valor[0] && valor[0].idioma === idioma) {
                                        setEmail(valor[0].valor);
                                    }
                                    if (keyValor === 'WhatsApp' && valor[0] && valor[0].idioma === idioma) {
                                        setWhatsapp(valor[0].valor);
                                    }
                                    if (keyValor === 'telefono' && valor[0] && valor[0].idioma === idioma) {
                                        setTelefono(valor[0].valor);
                                    }
                                    if (keyValor === 'direccion' && valor[0] && valor[0].idioma === idioma) {
                                        setDireccion(valor[0].valor);
                                    }
									if (keyValor === 'Facebook' && valor[0] && valor[0].idioma === idioma) {
                                        setFacebook(valor[0].valor);
                                    }
									if (keyValor === 'Instagram' && valor[0] && valor[0].idioma === idioma) {
                                        setInstagram(valor[0].valor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [contenido]);
    const getContenidoSeccion = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/seccion/contacto'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getContenidoSeccion response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data.datos) {
                    setContenido(response.data.datos);
                }
            }
        }, error => {
            Helper.log('error getContenidoSeccion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }
    return (
        <>
            {!props.history.location.pathname.includes('mi-perfil') ? <div id="footer" className="w3-block">
                <div className="w3-content">
                    <div className="w3-row">
                        <div className="w3-col l3 m6 s12 w3-padding bs-bbox">
                            <img src={Logo} alt="" style={{ width: '80%', maxWidth: '150px' }} />
                        </div>
                        <div className="w3-col l3 m6 s12 w3-padding bs-bbox">
                            <span className="xbold">Atención al cliente</span><br />
                            {email ? <a id="footer-email" className="text-decoration-none" href={`mailto:${email}`}><div className="w3-row">
                                <div className="w3-col" style={{width: '20px'}}>
                                    <FontAwesomeIcon icon={faEnvelope} color={Colors.THEME} />
                                </div>
                                <div className="w3-rest">
                                    <span>{email}</span>
                                </div>
                            </div></a> : null}
                            {whatsapp ? <a id="footer-whatsapp" className="text-decoration-none" href={`https://wa.me/${Helper.extraerTel(whatsapp)}`}><div className="w3-row">
                                <div className="w3-col" style={{width: '20px'}}>
                                    <FontAwesomeIcon icon={faWhatsapp} color={Colors.THEME} />
                                </div>
                                <div className="w3-rest">
                                    <span>{whatsapp}</span>
                                </div>                            
                            </div></a> : null}
                            {telefono ? <a id="footer-telefono" className="text-decoration-none" href={`tel:${Helper.extraerTel(telefono)}`}><div className="w3-row">
                                <div className="w3-col" style={{width: '20px'}}>
                                    <FontAwesomeIcon icon={faPhone} color={Colors.THEME} />
                                </div>
                                <div className="w3-rest">
                                    <span>{telefono}</span>
                                </div>
                            </div></a> : null}
                            {direccion ? <div className="w3-row">
                                <div className="w3-col" style={{width: '20px'}}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} />
                                </div>
                                <div className="w3-rest">
                                    <div id="footer-direccion" dangerouslySetInnerHTML={{ __html: direccion }} /></div>
                            </div> : null}
                        </div>
                        <div className="w3-col l3 m6 s12 w3-padding bs-bbox footer-social-container">
                            <span className="xbold">Seguinos</span><br />
                            <a href={facebook} rel="noopener noreferrer" target="_blank" className="w3-text-theme"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                            <a href={instagram} rel="noopener noreferrer" target="_blank" className="w3-text-theme"><FontAwesomeIcon icon={faInstagramSquare} /></a>
                            <br />
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div> : null}
        </>
    );
}
export default withRouter(Footer);